<template>
    <div   @dragenter="onEnter" @dragover="onOver" @drop.prevent="onDrop" @paste="onPaste" >
        <slot></slot>
     
        <div v-if="showDropZone" @dragleave="onLeave($event)" class="dropzone" :class="(!dataObject.allowInsert || !dataObject.allowUpdate) ? 'disabled' : ' '" >
        </div> 
        <UploadProgress  ref="uploadProgress"  :dataObject = "dataObject" :openDataLog = "openDataLog" :batch = "batch" ></UploadProgress>
     </div>
</template>

<script setup>
    import './DataObject.ImportTemplate.ts';
    import UploadProgress from "./Components.UploadProgress.vue";

    import { alert } from 'o365-vue-services';
    import {ref} from 'vue';
    const showDropZone = ref(false);
    const uploadProgress = ref(null);
    const emit = defineEmits(['completed','error']);
    const props = defineProps(['dataObject','beforeImport','batch', 'openDataLog', 'autoClose']);
    const onOver = (e)=>{
        if (e.dataTransfer && e.dataTransfer.types.indexOf("Files") > -1) {
            showDropZone.value = true;
            e.dataTransfer.dropEffect = "copy";
        }
        e.preventDefault();
    }
    const getOptions = ()=>{
        return {
            beforeImport:props.beforeImport,
            onCompleted:uploadCompleted,
            onError:uploadError,
            batch:props.batch != undefined && props.batch !== false?true:false
        }
    }    

    const onEnter = (e)=>{
        
        if (e.dataTransfer && e.dataTransfer.files.length) {
           showDropZone.value = true;
        }
        e.preventDefault();
    }
    const onLeave = (e)=>{
        showDropZone.value = false;
        e.preventDefault();
    }


    const uploadCompleted= (pProgress)=>{
        emit("completed",pProgress);
        if(props.autoClose){
            uploadProgress.value.dismiss();
        }
    }
    const onDrop = (e)=>{
        if (e.dataTransfer && e.dataTransfer.files.length && props.dataObject.allowInsert && props.dataObject.allowUpdate) {
            e.dataTransfer.dropEffect = "copy";
            const vOptions = getOptions();
            vOptions.file =e.dataTransfer.files[0] ;
            if(!isXlsxFile(vOptions.file.name)){
                uploadProgress.value.dismiss();
                showDropZone.value = false;
                alert($t(`Uploading file must be .xlsx`), 'danger')
                return;
            }     

            props.dataObject.importTemplate.importData(vOptions);
            showDropZone.value = false;
            uploadProgress.value.show();
            
        }
        if (!props.dataObject.allowInsert || !props.dataObject.allowUpdate) {
            uploadProgress.value.dismiss();


        }
    }
    function isXlsxFile(filename) {
    return filename.slice(-5) === '.xlsx';
    }
    const onPaste = (e)=>{
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        if(items.length > 0 && items[0].kind === 'file' && props.dataObject.allowInsert && props.dataObject.allowUpdate){
            const vOptions = getOptions();
            vOptions.file = e.clipboardData.items[0].getAsFile();
            if(!isXlsxFile(vOptions.file.name)){
                uploadProgress.value.dismiss();
                showDropZone.value = false;
                alert($t(`Uploading file must be .xlsx`), 'danger')
                return;
            }

            props.dataObject.importTemplate.importData(vOptions);
            uploadProgress.value.show();
            e.preventDefault();
        } 
        if (!props.dataObject.allowInsert || !props.dataObject.allowUpdate) {
            alert($t(`Upload is disabled for ${props.dataObject.id}`), 'warning')
        }
    }
    const uploadError= (pError)=>{
        emit("error",pError)
        uploadProgress.value.dismiss();

    }
</script>
<style>
     .dropzone:after {
        content: "Drop file here...";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        inset: 0;
        background-color: rgba(255, 255, 255, .8);
        border: 'dashed grey 4px';
        z-index: 1000;
    }
    .dropzone.disabled:after {
        content: "Upload is disabled...";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        inset: 0;
        background-color: rgba(255, 255, 255, .8);
        border: 'dashed grey 4px';
        z-index: 1000;
    }
    .disabled {
        pointer-events: none;
    }
</style>